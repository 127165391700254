import { createTheme, responsiveFontSizes } from "@mui/material/styles"

var theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      dark: "#003638",
      main: "#055052",
      light: "#53B8BB",
      contrastText: "#F3F2C9"
    },
    secondary: {
      dark: "#753422",
      main: "#B05B3B",
      light: "#D79771",
      contrastText: "#FFEBC9"
    }
  },
  typography: {
    h1: {
      fontSize: "3.5rem",
      fontWeight: 400
    },
    h2: {
      fontSize: "2.8rem",
      fontWeight: 300
    },
    h3: {
      fontSize: "2.1rem",
      fontWeight: 300
    },
    h4: {
      fontSize: "1.7rem",
      fontWeight: 200
    }
  },
  transitions: {
    easing: {
      nav: "cubic-bezier(0.2, 0.94, 0.36, 1)"
    },
    duration: {
      navIn: 1300,
      navOut: 500
    }
  }
})

theme = responsiveFontSizes(theme, {
  breakpoints: ["sm", "md"],
  factor: 3
})

export default theme
