import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"

function Ellipsis({ size, color }) {
  return (
    <Stack
      spacing={size / 8}
      direction="row"
      sx={{
        "& div": {
          bgcolor: color,
          height: size,
          width: size,
          borderRadius: "50%",
          animationName: "twinkle",
          animationDuration: "1s",
          animationTimingFunction: "linear",
          animationIterationCount: "infinite"
        },
        "@keyframes twinkle": {
          "0%": { opacity: 0.5 },
          "50%": { opacity: 1 },
          "100%": { opacity: 0.5 }
        }
      }}
    >
      {[0, 0.1, 0.2].map((num, key) => (
        <Box
          key={key}
          sx={{
            animationDelay: `${num}s`
          }}
        />
      ))}
    </Stack>
  )
}

Ellipsis.defaultProps = {
  size: 8,
  color: "primary.main"
}

export default Ellipsis
