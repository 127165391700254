import { useEffect, useRef } from "react"
import Box from "@mui/material/Box"

const src = "https://accounts.google.com/gsi/client"

const loadGoogle = src => {
  return new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve()
    const script = document.createElement("script")
    script.src = src
    script.onload = () => resolve()
    script.onerror = err => reject(err)
    document.body.appendChild(script)
  })
}

export default function GoogleSignIn({ submit, sx, text }) {
  const googleButton = useRef(null)

  useEffect(() => {
    loadGoogle(src)
      .then(() => {
        window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_ID,
          callback: submit
        })
        window.google.accounts.id.renderButton(googleButton.current, {
          theme: "filled_black",
          size: "large",
          text: text,
          shape: "rectangular"
        })
      })
      .catch(console.error) // eslint-disable-next-line
  }, [])

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", ...sx }}
      ref={googleButton}
    />
  )
}

export function googleLogout() {
  window.google && window.google.accounts.id.disableAutoSelect()
}
